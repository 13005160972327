<template>
  <v-container>
    <!-- {{ settings }} -->
    <v-row no-gutters>
      <v-col >
        <h2 style="color: grey">Settings</h2>
        <v-btn @click = "editSettings" color="primary" elevation="8" rounded class="mt-4 mb-5" v-if="edit == 0">Editar</v-btn>
        <v-btn @click = "saveSettings" color="primary" elevation="8" rounded class="mt-4 mb-5" v-if="edit == 1">Guardar</v-btn>
        <v-btn @click = "cancelSettings" color="primary" elevation="8" rounded class="mt-4 mb-5 ml-3" v-if="edit == 1">Cancelar</v-btn>
        <h3 style="color: grey" class="mb-5">Machine Stops and Environment</h3>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-text-field v-model="gatewayBle" label="GW Ble URL" dense outlined :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="3">
        <v-text-field v-model="accelerometer" label="Accelerometer" dense outlined :disabled="edit == false"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="accelerometerDesc" label="Accelerometer Description" dense outlined class="ml-3" :disabled="edit == false"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="environment" label="Sensor Environment" dense outlined class="ml-3" :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="4">
        <v-text-field v-model="pivot1" label="Pivot X" dense outlined class="mr-1" :disabled="edit == false"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="pivot2" label="Pivot Y" dense outlined class="ml-2" :disabled="edit == false"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field v-model="pivot3" label="Pivot Z" dense outlined class="ml-3" :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="6">
        <v-text-field v-model="stopWindow" label="Stop Window" dense outlined class="mr-3" :disabled="edit == false"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="recoveryRetry" label="Recovery Retry" dense outlined class="ml-3" :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

    <v-divider class="mt-3 mb-3"></v-divider>

    <v-row no-gutters>
      <v-col >
        <h3 style="color: grey" class="mb-5">Mesh</h3>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-text-field v-model="mqttUrl" label="MQTT Url" dense outlined :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="6">
        <v-text-field v-model="user" label="User" dense outlined class="mr-3" :disabled="edit == false"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="password" label="Password" dense outlined class="ml-3" :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-text-field v-model="anchors" label="Anchors" dense outlined :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-text-field v-model="tags" label="Tags" dense outlined :disabled="edit == false"></v-text-field>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import {db} from '../firebase'
import {ref, onValue, update} from "firebase/database";

export default {
  data () {
    return {
      settings: [],
      edit: false,

      //machineStops
      accelerometer : 0,
      accelerometerDesc : '',
      environment : 0,
      gatewayBle : '',
      pivot1 : 0,
      pivot2 : 0,
      pivot3 : 0,
      recoveryRetry : 0,
      stopWindow: 0,

      //mesh
      mqttUrl : '',
      user : '',
      password : '',
      anchors : '',
      tags : '',
    }
  },

  computed: {

    
  },

  methods: {
    getSettings(){
      const confRef = ref(db, 'configuration')
      onValue(confRef, snapshot => {
        //console.log(snapshot.val())
        this.settings = snapshot.val()

        this.accelerometer = snapshot.val().machineStops.accelerometer
        this.accelerometerDesc = snapshot.val().machineStops.accelerometerDesc
        this.environment = snapshot.val().machineStops.environment
        this.gatewayBle = snapshot.val().machineStops.gatewayBle
        this.pivot1 = snapshot.val().machineStops.pivot1
        this.pivot2 = snapshot.val().machineStops.pivot2
        this.pivot3 = snapshot.val().machineStops.pivot3
        this.recoveryRetry = snapshot.val().machineStops.recoveryRetry
        this.stopWindow = snapshot.val().machineStops.stopWindow

        this.mqttUrl = snapshot.val().mesh.mqttUrl
        this.user = snapshot.val().mesh.user
        this.password = snapshot.val().mesh.password
        this.anchors = snapshot.val().mesh.anchors
        this.tags = snapshot.val().mesh.tags
      })
    },

    editSettings(){
      this.edit = true
    },

    cancelSettings () {
      this.getSettings()
      this.edit = false
    },

    saveSettings () {
      update(ref(db, 'configuration'), {
        machineStops : {
          active : 0,
          accelerometer : this.accelerometer,
          accelerometerDesc : this.accelerometerDesc,
          environment : this.environment,
          gatewayBle : this.gatewayBle,
          pivot1 : this.pivot1,
          pivot2 : this.pivot2,
          pivot3 : this.pivot3,
          recoveryRetry : this.recoveryRetry,
          stopWindow : this.stopWindow
        },
        mesh : {
          mqttUrl : this.mqttUrl,
          user : this.user,
          password : this.password,
          anchors : this.anchors,
          tags : this.tags
        }
      })
      this.edit = false
    }



  },

  watch: {
    
  },

  created() {
    this.getSettings()
  },
}
</script>